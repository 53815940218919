import { ICameraCCTV } from '@api/models';
import { Drawer, NavTab } from '@hyperflake/react-ui-library';
import { LinearProgress } from '@library/loaders/components';
import useCctvCameraData from 'modules/cctv-cameras/hooks/useCctvCameraData';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import CctvCameraEditForm from '../CctvCameraEditDrawerForm/CctvCameraEditDrawerForm';
import ChangeProjectForm from '../ChangeProjectForm/ChangeProjectForm';

enum TabEnum {
    BASIC_DETAILS,
    CHANGE_PROJECT,
}
interface CctvCameraDrawerLayoutProps {
    open: boolean;
    onClose: () => void;
    cctvCameraId: string;
    onUpdateCctvCamera: (cctvCamera: ICameraCCTV) => void;
}
const CctvCameraDrawerLayout = (props: CctvCameraDrawerLayoutProps) => {
    const { open, onClose, cctvCameraId, onUpdateCctvCamera } = props;
    const { clientId, projectId } = useParams();

    const { cctvCamera, isLoading, setData } = useCctvCameraData(clientId, projectId, cctvCameraId);
    const [activeTab, setActiveTab] = useState(TabEnum.BASIC_DETAILS);
    return (
        <Drawer open={open} onClose={onClose} title="Edit Live Camera">
            <NavTab
                items={[
                    {
                        content: 'Basic',
                        isActive: TabEnum.BASIC_DETAILS === activeTab,
                        onClick: () => setActiveTab(TabEnum.BASIC_DETAILS),
                    },

                    {
                        content: 'Change Project',
                        isActive: TabEnum.CHANGE_PROJECT === activeTab,
                        onClick: () => setActiveTab(TabEnum.CHANGE_PROJECT),
                    },
                ]}
            />
            <div className="mt-6">
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div>
                        {activeTab === TabEnum.BASIC_DETAILS && (
                            <CctvCameraEditForm
                                onClose={onClose}
                                cctvCamera={cctvCamera}
                                onUpdateCctvCamera={onUpdateCctvCamera}
                            />
                        )}

                        {activeTab === TabEnum.CHANGE_PROJECT && (
                            <ChangeProjectForm cctvCamera={cctvCamera} onClose={onClose} />
                        )}
                    </div>
                )}
            </div>
        </Drawer>
    );
};
export default CctvCameraDrawerLayout;
