import { UnitTypeEnum } from '@api/enum';
import { ICamera } from '@api/models';
import { createCamera } from '@api/services/camera.service';
import { useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import capitalize from 'lodash.capitalize';
import { resolutionOption, timezoneList } from 'modules/shared/helpers/shared.constants';
import moment from 'moment';
import { useMemo } from 'react';
import * as Yup from 'yup';

const useAddCameraForm = (clientId: string, projectId: string) => {
    const addCameraMutation = useMutation((payload: any) => createCamera(clientId, projectId, payload));

    const schema = Yup.object().shape({
        // _id: Yup.string(),
        name: Yup.string().trim().required('This field is required'),
        installationDate: Yup.string().trim().required('This field is required'),
        details: Yup.object().shape({
            type: Yup.string().oneOf(Object.values(UnitTypeEnum)),
            version: Yup.string(),
        }),
        timezone: Yup.string().trim().required('This field is required'),
        orientation: Yup.number()
            .required('This field is required')
            .typeError('You must specify a number')
            .min(0, 'Value must be greater or equal to zero')
            .max(360, 'Value must be less or equal to 360'),
        storage: Yup.string().required('This field is required'),

        folder: Yup.string()
            .nullable()
            .when('isPointedTo', {
                is: true,
                then: (schema) => schema.required('Folder ID is required when Pointed  is enabled.'),
                otherwise: (schema) => schema.notRequired(),
            }),
        isPointedTo: Yup.boolean(),
    });
    const initialValues = {
        // _id: '',
        name: '',
        installationDate: moment().toDate(),
        details: {
            type: UnitTypeEnum.IRIS,
            version: '',
        },
        timezone: '',
        orientation: '0',
        storage: '',
        folder: '',
        latestImage: {
            name: '',
            resolution: JSON.stringify(resolutionOption[(resolutionOption.length - 1) / 2]),
            // resolution: { width: 4496, height: 3000 },
        },
        location: {
            latitude: '',
            longitude: '',
        },
        isPointedTo: false,
    };
    const unitTypeOption = useMemo(() => {
        const temp = Object.values(UnitTypeEnum);
        return temp.map((val) => ({
            label: capitalize(val),
            value: val,
        }));
    }, []);
    const timezoneOption = useMemo(() => {
        return [
            {
                label: 'Select Timezone',
                value: '',
            },
            ...timezoneList.map((tz) => ({
                label: tz.label,
                value: tz.value,
            })),
        ];
    }, []);

    // const addCameraMutation = useMutation((payload: any) => addClient(payload));
    const submit = async (values: ICamera) => {
        const data = await addCameraMutation.mutateAsync(values);

        return data;
    };
    const handleDataTransformation = (values: any) => {
        return produce(values, (draft: ICamera) => {
            draft.installationDate = moment(draft.installationDate).format('YYYYMMDD');
            draft.folder = values.isPointedTo ? (draft.folder.trim() === '' ? null : draft.folder.trim()) : null;
            // draft._id = draft._id.trim() === '' ? null : draft._id.trim();
            draft.latestImage = draft.latestImage.name === '' ? null : draft.latestImage;
            // draft.latestImage = {
            //     name: values.latestImage.name,
            //     resolution: resolutionOption[parseInt(values.latestImage.resolution)],
            // };
        });
    };

    return {
        schema,
        initialValues,
        unitTypeOption,
        timezoneOption,
        handleDataTransformation,
        submit,
        isSubmitting: addCameraMutation.isLoading,
    };
};
export default useAddCameraForm;
