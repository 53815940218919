import { IInternalApplications } from '@api/models';
import { getInternalApplicationUrlById } from '@api/services/internal-applications.service';
import Img from '@components/Img/Img';
import useDataQuery from '@hooks/use-data-query';
import { CircularProgress } from '@hyperflake/react-ui-library';
import { useMutation } from '@tanstack/react-query';
import { GalleryCard } from 'modules/shared/features/content';
interface ImageDownloaderProps {
    data: IInternalApplications;
}

const InternalApplicationCard = (props: ImageDownloaderProps) => {
    const { data } = props;
    const internalApplicationMutation = useMutation((os: string) => getInternalApplicationUrlById(data._id, os));

    const handleDownload = async () => {
        const link = document.createElement('a');

        const userAgent = window.navigator.userAgent;

        if (userAgent.includes('Mac')) {
            // Logic for macOS

            const internalApplicationWithUrl = await internalApplicationMutation.mutateAsync('mac');
            link.href = internalApplicationWithUrl.url;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            // Logic for other operating systems

            const internalApplicationWithUrl = await internalApplicationMutation.mutateAsync('windows');
            link.href = internalApplicationWithUrl.url;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    return (
        <GalleryCard>
            <div className="p-4">
                <div className="flex justify-end">
                    <div
                        className={`inline-flex text-xs px-3 py-2 rounded-full font-semibold  bg-green-500 text-white`}
                        onClick={handleDownload}
                    >
                        {internalApplicationMutation.isLoading
                            ? // <CircularProgress size={10} />
                              'Downloading...'
                            : 'Download'}
                    </div>
                </div>

                <img className="max-h-10 max-w-[140px] mt-4" src="/logos/logo-full-dark.svg" alt="" />
                <div className="font-bold mt-4">{data.name}</div>
                <p className="mt-4 text-sm">{data.description}</p>
            </div>
        </GalleryCard>
    );
};

export default InternalApplicationCard;
