import http from 'httpConfig/http';
import moment from 'moment';
// import { Table } from '@library/tables';
import { CameraStatusEnum } from '@api/enum/camera-status.enum';
import { ICameraCCTV } from '@api/models/camera-cctv.model';
import useNavName from '@hooks/useNavName';
import { Drawer } from '@library/drawer';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CctvCameraAddDrawerForm from '../CctvCameraAddDrawerForm/CctvCameraAddDrawerForm';

import { PermissionEnum } from '@api/enum/permission.enum';
import { Button, Chip, Table } from '@hyperflake/react-ui-library';
import { EllipsisVerticalIcon } from '@icon/index';
import { EmptyDataView, PageHeading, PageTemplate } from 'modules/shared/features/content';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import CctvCameraDeleteConfirmationDrawer from '../CctvCameraDeleteConfirmationDrawer/CctvCameraDeleteConfirmationDrawer';
import CctvCameraEditDrawerForm from '../CctvCameraEditDrawerForm/CctvCameraEditDrawerForm';
import EventBlocker from '@components/EventBlocker/EventBlocker';
import CctvCameraCard from '../CctvCameraCard/CctvCameraCard';
import useDialog from '@hooks/use-dialog';
import { useCctvCameraListData } from 'modules/cctv-cameras/hooks/useCctvCameraListData';
import { BlockLoader } from 'modules/shared/features/loaders';
import CctvCameraDrawerLayout from '../CctvCameraDrawerLayout/CctvCameraDrawerLayout';

const CctvCameraList = () => {
    const { clientId, projectId } = useParams();
    const navigate = useNavigate();
    const { hasPermission } = useAuthPermission();
    const editDrawerDialog = useDialog<string>();
    const deleteDrawerDialog = useDialog<ICameraCCTV>();
    const addDrawerDialog = useDialog();
    const { cctvCameras, isLoading, addCctvCamera, updateCctvCamera } = useCctvCameraListData(clientId, projectId);

    // const [addDrawer, setAddDrawer] = useState({ open: false, anchor: 'right' });

    // const [editDrawer, setEditDrawer] = useState({
    //     open: false,
    //     anchor: 'right',
    //     id: '',
    // });
    // const [confirmationDrawer, setConfirmationDrawer] = useState<{
    //     open: boolean;
    //     anchor: string;
    //     ccCamera: null | ICameraCCTV;
    // }>({ open: false, anchor: 'right', ccCamera: null });

    // const { clientName, projectName } = useNavName(clientId, projectId);

    // const navArray = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     {
    //         label: 'Projects',
    //         onClick: () => navigate(`/client/${clientId}/project`),
    //     },
    //     {
    //         label: projectName,
    //         onClick: () => navigate(`/client/${clientId}/project?query=${projectId}`),
    //     },
    //     { label: 'Cctv Cameras', onClick: () => {} },
    // ];

    // const { data, isLoading } = useQuery<ICameraCCTV[]>(
    //     ['clients', clientId, 'projects', projectId, 'cctv-cameras'],
    //     async () => {
    //         const { data } = await http.get(
    //             `${
    //                 import.meta.env.VITE_APP_API_URL
    //             }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/camera-cctvs`
    //         );
    //         return data;
    //     }
    // );

    // const cctvCameras = useMemo(() => {
    //     if (!data) return [];
    //     return data;
    // }, [data]);

    const renderStatusChip = (status: CameraStatusEnum) => {
        let statusColor: 'primary' | 'warning' | 'success' | 'danger' | 'secondary';

        switch (status) {
            case CameraStatusEnum.ENABLED:
                statusColor = 'success';
                break;
            case CameraStatusEnum.DISBALED:
                statusColor = 'warning';
                break;
            case CameraStatusEnum.DELETED:
                statusColor = 'danger';
                break;
            default:
                statusColor = 'primary';
                break;
        }

        return <Chip label={status} variant={'outlined'} color={statusColor} />;
    };

    if (isLoading) return <BlockLoader />;
    return (
        <PageTemplate>
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}
            {/* <Breadcrumb separator="/" items={navArray} /> */}
            <div className="flex justify-between">
                <PageHeading>Live Camera</PageHeading>
                {hasPermission(PermissionEnum.LIVE_CAMERA_CREATE) && (
                    <Button onClick={() => addDrawerDialog.show()}>Add New Live Camera</Button>
                )}
            </div>

            {/* <div className="mt-6">
                    <h3>Live Camera</h3>
                </div> */}

            <div>
                {/* <Table
                    borderStyle="divider"
                    className="mt-6"
                    hover={false}
                    data={cctvCameras}
                    columns={[
                        // {
                        //     header: '#',
                        // },
                        {
                            header: 'ID',
                        },
                        {
                            header: 'Name',
                        },
                        {
                            header: 'Installation Date',
                        },
                        {
                            header: 'Streaming URL',
                        },
                        {
                            header: 'Status',
                        },
                        {
                            header: 'Action',
                        },
                    ]}
                    renderRow={(row) => (
                        <Table.Row
                            key={row._id}
                            onClick={() =>
                                setEditDrawer({
                                    open: true,
                                    anchor: 'right',
                                    id: row._id,
                                })
                            }
                        >
                            <Table.Cell>{row._id}</Table.Cell>

                            <Table.Cell>
                                <div>
                                    <div>{row.name}</div>
                                    <div
                                        onClick={() => alert('Click not handled yet')}
                                        className="text-primary text-xs cursor-pointer"
                                    >
                                        {row.pointedTo ? `Points to ${row.pointedTo}` : ''}{' '}
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div>
                                    {row.installationDate
                                        ? moment(row.installationDate, 'YYYYMMDD').format('DD MMM YYYY')
                                        : '-'}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <div>{row.streamingUrl}</div>
                            </Table.Cell>
                            <Table.Cell>
                                <div>{renderStatusChip(row.status)}</div>
                            </Table.Cell>
                            <Table.Cell>
                                <EventBlocker stopPropagation preventDefault>
                                    <>
                                        <Dropdown placement="bottom-start" showArrow>
                                            <DropdownTrigger className="w-4 h-4 rounded-full">
                                                <EllipsisVerticalIcon />
                                            </DropdownTrigger>

                                            <DropdownList className="min-w-[100px]">
                                                <DropdownItem
                                                    className=""
                                                    onClick={() =>
                                                        setEditDrawer({
                                                            open: true,
                                                            anchor: 'right',
                                                            id: row._id,
                                                        })
                                                    }
                                                >
                                                    Edit
                                                </DropdownItem>
                                                {hasPermission(PermissionEnum.LIVE_CAMERA_DELETE) && (
                                                    <DropdownItem
                                                        onClick={() =>
                                                            setConfirmationDrawer({
                                                                open: true,
                                                                anchor: 'right',
                                                                ccCamera: row,
                                                            })
                                                        }
                                                        className="text-danger"
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                )}

                                            </DropdownList>
                                        </Dropdown>
                                    </>
                                </EventBlocker>
                            </Table.Cell>
                        </Table.Row>
                    )}
                /> */}
                <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
                    {cctvCameras.map((cctvCamera) => (
                        <div
                            className="col-span-1"
                            key={cctvCamera._id}
                            // onClick={() =>
                            //     navigate(
                            //         `/clients/${clientId}/projects/${projectId}/cameras/${cctvCamera._id}/${cctvCamera.name}`
                            //     )
                            // }
                        >
                            <CctvCameraCard
                                cctvCamera={cctvCamera}
                                onEditClick={() => editDrawerDialog.show(cctvCamera._id)}
                                onDeleteClick={(cctvCamera) => deleteDrawerDialog.show(cctvCamera)}
                                // setEditDrawer={setEditDrawer}
                                hasPermission={hasPermission(PermissionEnum.LIVE_CAMERA_DELETE)}
                            />
                        </div>
                    ))}
                </div>

                {cctvCameras.length === 0 && <EmptyDataView message="No Live Cameras Found" />}
                {isLoading && <BlockLoader />}
                {/* {!isLoading && cctvCameras.length === 0 && <EmptyTableTemplate />}
                {isLoading && <TableDataLoading />} */}
            </div>

            {/* </div> */}

            {/* Add Cctv Drawer */}
            {/* <Drawer
                open={addDrawer.open}
                onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                anchor={addDrawer.anchor as any}
            >
                <CctvCameraAddDrawerForm onClose={() => setAddDrawer({ open: false, anchor: 'right' })} />
            </Drawer> */}

            {/* Edit Cctv Drawer */}
            {/* <Drawer
                open={editDrawer.open}
                onClose={() => setEditDrawer({ open: false, anchor: 'right', id: '' })}
                anchor={editDrawer.anchor as any}
            >
                <CctvCameraEditDrawerForm
                    cctvCameraId={editDrawer.id}
                    onClose={() => setEditDrawer({ open: false, anchor: 'right', id: '' })}
                />
            </Drawer> */}
            <CctvCameraAddDrawerForm
                open={addDrawerDialog.isOpen}
                onClose={addDrawerDialog.hide}
                onAddCctvCamera={addCctvCamera}
            />

            <CctvCameraDrawerLayout
                open={editDrawerDialog.isOpen}
                cctvCameraId={editDrawerDialog.data}
                onClose={editDrawerDialog.hide}
                onUpdateCctvCamera={(cctvCamera) => updateCctvCamera(cctvCamera._id, cctvCamera)}
            />

            <CctvCameraDeleteConfirmationDrawer
                open={deleteDrawerDialog.isOpen}
                onClose={deleteDrawerDialog.hide}
                ccCamera={deleteDrawerDialog.data}
            />
        </PageTemplate>
    );
};

export default CctvCameraList;
